<template>
  <!--
    wangeDitorConfig 富文本配置

    height  文本高度 val:数字
    focus   自动焦点 val:布尔值
    placeholder   修改 placeholder 的提示文字

-->
  <div ref="wangeditor"></div>
</template>

<script>
// Typescript 开发的 Web 富文本编辑器
import E from 'wangeditor'
import { uploadFile } from '../api/aliyunOss.js'

export default {
  props: {
    wangeDitorConfig: Object,
    value: {
      type: String,
      default: ''
    },
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      editor: null,
    }
  },
  mounted () {
    this.init()
    this.editor.txt.html(this.value)
  },
  watch: {
    value: function (value) {
      if (value !== this.editor.txt.html()) {
        this.editor.txt.html(this.value)
      }
    }
  },
  methods: {
    init () {
      this.editor = new E(this.$refs.wangeditor)

      // 设置编辑区域高度为 500px
      this.editor.config.height = this.wangeDitorConfig.height
      // 取消自动 focus （焦点）
      this.editor.config.focus = this.wangeDitorConfig.focus
      //自定义 placeholder 提示文字
      this.editor.config.placeholder = this.wangeDitorConfig.placeholder



      // 默认情况下，只有 IE 和 旧版 Edge 会使用兼容模式，如果需要在其它浏览器上也使用兼容模式，可以在函数内进行判定
      this.editor.config.compatibleMode = function () {
        // 返回 true 表示使用兼容模式；返回 false 使用标准模式
        return true
      }

      // 菜单栏提示为上标（默认配置）
      // editor.config.menuTooltipPosition = 'up'

      // 菜单栏提示为下标
      this.editor.config.menuTooltipPosition = 'down'

      // 配置 onchange 回调函数
      this.editor.config.onchange = function (newHtml) {
        console.log('change 之后最新的 html', newHtml)
      }
      // 配置触发 onchange 的时间频率，默认为 200ms
      this.editor.config.onchangeTimeout = 500 // 修改为 500ms

      // 配置 server 接口地址
      this.editor.config.uploadImgServer = '/api/img'
      // this.editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图片 
      this.editor.config.customUploadImg = (files, insert) => {
        console.log(files)
        files.forEach(item => {
          uploadFile(item).then(res => {
            console.log(res)
            insert(res)

            this.$message.success('上传成功')
          })
        })
        // const res = await uploadFile(files[0])
        // insert(res)
      }

      // 隐藏插入网络图片的功能
      this.editor.config.showLinkImg = false

      // 以 Ant Design 为例
      this.editor.config.customAlert = function (s, t) {
        this.$message("找到我了")
        switch (t) {
          case 'success':
            this.$message({
              message: s,
              type: 'success'
            });
            break;
          case 'info':
            this.$message(s);
            break;
          case 'warning':
            this.$message({
              message: s,
              type: 'warning'
            });
            break;
          case 'error':
            this.$message({
              message: s,
              type: 'error'
            });
            break;
          default:
            this.$message(s);
            break;
        }
      }
      // 配置颜色（文字颜色、背景色）
      this.editor.config.colors = [
        '#000000',
        '#eeece0',
        '#1c487f',
        '#4d80bf'
      ]
      // 配置 onchange 回调函数
      this.editor.config.onchange = (newHtml) => {
        this.$emit('change', newHtml)
      }
      this.editor.create()
    },

  }
}
</script>

<style lang="scss" scoped>
#wangeditor {
  height: 100%;
}
</style>
